<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto">Connections</div>
      <v-tooltip
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              @click="showDialog = true"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Service</span>
        </v-tooltip>
        <v-dialog
          v-model="showDialog"
          :fullscreen="isFullscreen"
          persistent
          max-width="65%"
        >
          <v-card>
            <v-card-title>
              <v-row no-gutters>
                <v-col>
                  <span class="headline">MQTT Connections</span>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    x-small
                    icon
                    @click="isFullscreen = !isFullscreen"
                  >
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <mqtt-connections-form ref="connection" @valid="valid = $event" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                small
                rounded
                dark
                @click="handleCancelCreateConnection()"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Close
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="success"
                small
                rounded
                @click="handleCreateConnection()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
    <list-item :items="connectionsList" title="id" icon="mdi-link" hasDelete @delete="deleteConnection($event)"></list-item>
    <loading-list v-if="!isFetch" />
    <confirm-dialog v-model="confirm.active" :type="confirm.type" :title="confirm.id" @confirm="confirmDialog(confirm.id)"></confirm-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import api from '@/services/api'

export default {
  name: 'MQTTConnections',
  components: {
    MqttConnectionsForm: () => import('@/components/mqtt/MqttConnectionsForm'),
    LoadingList: () => import('@/components/LoadingList'),
    ListItem: () => import('@/components/ListItem'),
    // LoadingPage: () => import('@/components/LoadingPage'),
    // EmptyPage: () => import('@/components/EmptyPage'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  data: () => ({
    showDialog: false,
    isFullscreen: false,
    dialogType: 'cancle',
    valid: true,
    connections: [],
    confirm: {
      type: 'delete',
      active: false,
      id: null
    },
    isFetch: false
  }),
  methods: {
    fetchMQTTConnections: function () {
      let vm = this
      api.get(`/api/services/${this.fiwareService}/connections`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            vm.connections = json
            vm.isFetch = true
          })
        }
      })
    },
    handleCreateConnection: function () {
      const validate = this.$refs.connection.$refs.form.validate()
      this.valid = validate
      if (this.valid) {
        let vm = this
        this.$refs.connection.createConnection().then((result) => {
          vm.connections = [...vm.connections, result]
        })
        this.showDialog = false
      }
    },
    handleCancelCreateConnection: function () {
      this.$refs.connection.resetValidateForm()
      this.showDialog = false
    },
    confirmDialog: function (serviceId) {
      if (this.dialogType === 'create') {
        this.$refs.connection.createConnection()
      } else if (this.dialogType === 'cancel') {
        this.$refs.connection.resetValidateForm()
        this.showDialog = false
      }
      this.$emit('input', false)
    },
    deleteConnection: function (id) {
      let vm = this
      return api.delete(`/api/services/${this.fiwareService}/connections/${id}`, null, null, this.$store.state.tokenRPT).then((_) => {
        let idx = vm.connections.findIndex((i) => i['_id'] === id)
        if (idx > -1) {
          vm.connections = [...vm.connections.slice(0, idx),
            ...vm.connections.slice(idx + 1)]
        }
      })
    }
  },
  computed: {
    ...mapGetters(['username', 'temp', 'mepBackend', 'fiwareService']),
    connectionsList () {
      return this.connections.map((c) => ({
        id: c._id,
        title: c.name
      }))
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      // console.log(this.$store.state.fiwareService)
      this.fetchMQTTConnections()
    }
  },
  created () {
    this.fetchMQTTConnections()
  }
}
</script>
